import React, { Component} from 'react';
import { TournamentsContext } from '../../contexts/TournamentProvider/TournamentProvider';

class GenerateTournament extends Component {
  static contextType = TournamentsContext;
  render() { 
    let context = this.context;

    return ( 
      <div className="generate-tournament-btns-wrapper">
        <button onClick={()=>{context.generateTournament('cup')}} className="generate-tournament-btn generate-cup">
          Create Cup
        </button>
        <button onClick={()=>{context.generateTournament('league')}} className="generate-tournament-btn generate-league">
          Create League
        </button>
      </div>
     );
  }
}
 
export default GenerateTournament;