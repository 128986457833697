import React, {Component} from 'react';
import './createTourOptions.css';

// components
import { TournamentsContext } from '../../contexts/TournamentProvider/TournamentProvider';
import Players from './players';
import GenerateTournament from './generateTournament'

export default class CreateTourOptions extends Component {  
  static contextType = TournamentsContext;

  render() {
    return (
      <div className="">
        <Players />
        <GenerateTournament />
      </div>
    )
  }
}