import React, { Component } from 'react';
// import testData from '../../testData';

export const TournamentsContext = React.createContext();

class TournamentProvider extends Component {
  state = { 
    tournaments: [],
    creatingTour: false,
    createTourFields: {
      players: [
        {name: 'Jona', key: 0, won: 0, draw: 0, lost: 0, pts: 0, goalsF: 0, goalsA: 0},
        {name: 'Moses', key: 1, won: 0, draw: 0, lost: 0, pts: 0, goalsF: 0, goalsA: 0},
        {name: 'Karan', key: 2, won: 0, draw: 0, lost: 0, pts: 0, goalsF: 0, goalsA: 0},
        {name: 'Andrew', key: 3, won: 0, draw: 0, lost: 0, pts: 0, goalsF: 0, goalsA: 0},
      ]
    }
  }

  //  View Tournament Details
  toggleDetails = (tournamentId) => {
    let newTournamentList, tournamentIndex;
    
    newTournamentList = this.state.tournaments;
    tournamentIndex = newTournamentList.findIndex(obj=> obj['_id'] === tournamentId); //Find index of selected tournament

    // Stop scrolling on Body when modal is open
    document.body.classList.contains("stop-scroll") === true
      ? document.body.classList.remove("stop-scroll")
      : document.body.classList.add("stop-scroll")

    newTournamentList[tournamentIndex].isActive = !newTournamentList[tournamentIndex].isActive; // Change isActive from true to false or viceversa
    this.setState({tournaments: newTournamentList}) // Update state
  }
  // Open modal to CREATE a tournament
  toggleCreateTourModal = () => {
    let creatingTour = this.state.creatingTour;

    // Stop scrolling on Body when modal is open
    document.body.classList.contains("stop-scroll") === true
      ? document.body.classList.remove("stop-scroll")
      : document.body.classList.add("stop-scroll")

    this.setState({creatingTour: !creatingTour})
  }
  // Add Player when creating tournament
  addPlayer = () => {
    let newPlayers = this.state.createTourFields;
    let numberOfPlayers = newPlayers.players.length;
    newPlayers.players.push({name: '', key: numberOfPlayers, won: 0,draw: 0,lost: 0,pts: 0,goalsF: 0,goalsA: 0});

    this.setState({createTourFields: newPlayers})
  }
  // Handle player name changes
  changePlayerName = (evt) => {
    let newPlayerName = this.state.createTourFields;
    let playerId = evt.target.dataset.playerid; // data ID in the input tag
    let value = evt.target.value;

    newPlayerName.players.map(player => {
      if(player.key === parseInt(playerId)) {
        return player.name = value;
      }
      return null
    })

    this.setState({createTourFields: newPlayerName})
  }
  // Create a new tournament 
  generateTournament = (type) => {
    // type will be either 'cup' or 'league'
    let newTournaments = this.state.tournaments;
    // players for new tournament
    let players = this.state.createTourFields.players, resetPlayers =[];
    players.map(player =>{
      // we will assign resetPLayers to the data property in the new tournament
      resetPlayers.push(Object.assign({}, player))
    })

    // assign a random ID to the tournament
    let randomID = Math.random().toString(36).substr(2, 9);

    let rounds;


    if(type==='cup') { 
     rounds = this.createNewCup(players)
     console.log('cup rounds!: ', rounds) 
    }
    else if(type==='league'){
      rounds = this.createNewLeague(players)
    }
    
    let tournamentData = {   
      "type": type, 
      "title": "Fifa Tournament",
      "tournamentType": type,
      "_id": randomID,
      "isActive": false,
      "isFinished": true,
      "winner": "Winner's name",
      "data": resetPlayers,
      "rounds": rounds
    };
    console.log('new tournament data: ', tournamentData)

    newTournaments.push(tournamentData);

    this.setState(
      // Update tournaments array
      {tournaments: newTournaments}, 
      ()=>{
        // Close createtournament modal
        this.toggleCreateTourModal()
        // Genarte cup/league settings
      }
    )
  }
  // Create a new cup
  createNewCup = (teams) => {
    let roundMatchingOrder = {
      roundOf16 : [0, 4, 2, 6, 1, 5, 3, 7, 0, 4, 2, 6, 1, 5, 3, 7],
      quarterFinals : [0, 2, 1, 3, 0, 2, 1, 3],
      semiFinals: [0, 0, 1, 1]
    }
    let fixedRounds = {
      roundOf16: [], // 16 teams 
      quarterFinals: [], // 8 teams
      semiFinals: [], // 4 teams
      final: []
    }
    let numOfTeams = teams.length;
    let round16matches = [], quarterFinMatches = [], semiFinMatches =[], matchingOrderR16, matchingOrderQF, matchingOrderSF; 
    
    // NUM OF TEAMS > 8 ====
    if(numOfTeams > 8 && numOfTeams <= 16) {
      matchingOrderR16 = roundMatchingOrder['roundOf16'];
      matchingOrderQF = [0, 0, 1, 1, 2, 2, 3, 3];
      // Creating matches for round of 16 phase
      // We have 16 teams in round of 8
      for(let i = 0; i < 16; i++) {
        if(round16matches[matchingOrderR16[i]] == undefined) {round16matches[matchingOrderR16[i]]  = []}
        // Looping throught the teams array we will push teams to different matches
        if(teams[i] != undefined ) { round16matches[matchingOrderR16[i]].push(teams[i])}
        // If we run out of teams that means that some teams will match against 'bye'
        if(teams[i] == undefined ) { round16matches[matchingOrderR16[i]].push(null)}
      }
      fixedRounds.roundOf16 = round16matches;
  
      // We will also create the matches for quarter finals
      // If we have 16 players we don't have to do it
      // But if we have less than 16 players some teams will automatically go to quarter finals
      // We have 8 teams and 4 matches in quarter finals
      for(let i = 0; i < 8; i++) {
        let currentQFmatch = fixedRounds.roundOf16[i]
        if(quarterFinMatches[matchingOrderQF[i]] == undefined) { quarterFinMatches[matchingOrderQF[i]] = []}
        // 
        if(currentQFmatch[1] == null) {quarterFinMatches[matchingOrderQF[i]].push(currentQFmatch[0])}
        if(currentQFmatch[1] != null) {quarterFinMatches[matchingOrderQF[i]].push(null)}
      }
      fixedRounds.quarterFinals = quarterFinMatches;
      fixedRounds.semiFinals = [[null, null], [null, null]];

    }

    // NUM OF TEAMS > 4 ====
    if(numOfTeams > 4 && numOfTeams <= 8) {
      matchingOrderQF = roundMatchingOrder['quarterFinals'];
      matchingOrderSF = roundMatchingOrder['semiFinals']
      for(let i = 0; i < 8; i++) {
        if(quarterFinMatches[matchingOrderQF[i]] == undefined) {quarterFinMatches[matchingOrderQF[i]]  = []}
        // Looping throught the teams array we will push teams to different matches
        if(teams[i] != undefined ) { quarterFinMatches[matchingOrderQF[i]].push(teams[i])}
        // If we run out of teams that means that some teams will match against 'bye'
        if(teams[i] == undefined ) { quarterFinMatches[matchingOrderQF[i]].push('bye')}
      }
      fixedRounds.quarterFinals = quarterFinMatches;
  
      for(let i = 0; i < 4; i++) {
        let currentQFmatch = fixedRounds.quarterFinals[i]
        if(semiFinMatches[i] == undefined) { semiFinMatches[i] = []}
        // 
        if(currentQFmatch[1] == 'bye') {semiFinMatches[matchingOrderSF[i]].push(currentQFmatch[0])}
        if(currentQFmatch[1] != 'bye') {semiFinMatches[matchingOrderSF[i]].push(null)}
      }
      fixedRounds.semiFinals = semiFinMatches;
  
      console.log("cup ROUNDS!: ",fixedRounds)
    }
    
    // NUM OF TEAMS == 4 ====
    if (numOfTeams == 4 ){
      console.log("semiFinals~")
      matchingOrderSF = roundMatchingOrder.semiFinals;
    
      for(let i = 0; i < 4; i++) {
        if(semiFinMatches[matchingOrderSF[i]] == undefined) { semiFinMatches[matchingOrderSF[i]] = []}
    
        semiFinMatches[matchingOrderSF[i]].push(teams[i])
      }
      fixedRounds.semiFinals = semiFinMatches;
  
    }
    
    return fixedRounds
  } 
  // Create a new cup
  createNewLeague = (teams) => {
    return this.roundRobinGenerator(teams);
  }

  // Toggle Game is finished 
  toggleGameIsFinished = (tournamentId, roundIndex, matchIndex) => {
    let updatedTournaments = this.state.tournaments;

    updatedTournaments.map(tournament => {
      if(tournament['_id'] === tournamentId) {
        let gameFinished = tournament.rounds[roundIndex][matchIndex][2].finished
        // console.log(tournament.rounds[roundIndex][matchIndex][2])
        tournament.rounds[roundIndex][matchIndex][2] = !gameFinished;
      }
    })

    updatedTournaments.map(tournament => {
      tournament = Object.assign({}, tournament)
    })

    this.setState({tournaments: updatedTournaments}, this.updateResultsTable(tournamentId))
  }

  // Update match results
  updateMatchResults = (score, tournamentId, roundIndex, matchIndex, playerIndex, playerName) => {
    let updatedTournaments = this.state.tournaments;
    updatedTournaments.map(tournament => {
      if(tournament['_id'] === tournamentId) {
        tournament.rounds[roundIndex][matchIndex][playerIndex].goals = score;
      }
    })
    this.setState({tournaments: updatedTournaments}, ()=>{this.updateResultsTable(tournamentId)})
  }

  // Update table
  updateResultsTable = (tournamentId) => {
    let team1Name, team2Name, team1Goals, team2Goals, gameFinished
    let newTournaments = this.state.tournaments;
    let teamsData = {};

    newTournaments.map(tournament => {
      if(tournament['_id'] === tournamentId) {

        tournament.rounds.map(round => {
          round.map(match => { 
            team1Name = match[0].name; team2Name = match[1].name; 
            team1Goals = match[0].goals;   team2Goals = match[1].goals;
            gameFinished = match[2];
            if(gameFinished === true) {
              if(team1Goals === '') { team1Goals = 0} 
              if(team2Goals === '') { team2Goals = 0} 
              if(teamsData[team1Name] === undefined) {teamsData[team1Name] = {name: team1Name, goals: 0, won: 0, draw: 0, lost: 0, pts: 0, goalsF: 0, goalsA: 0}}
              if(teamsData[team2Name] === undefined) {teamsData[team2Name] = {name: team2Name, goals: 0, won: 0, draw: 0, lost: 0, pts: 0, goalsF: 0, goalsA: 0}}
              // Goals Team1
              teamsData[team1Name].goals += parseInt(team1Goals)
              teamsData[team1Name].goalsF += parseInt(team1Goals)
              teamsData[team1Name].goalsA += parseInt(team2Goals)
              // Goals Team2
              teamsData[team2Name].goals += parseInt(team2Goals)
              teamsData[team2Name].goalsF += parseInt(team2Goals)
              teamsData[team2Name].goalsA += parseInt(team1Goals)
              // Points and stats
              if(team1Goals > team2Goals) { teamsData[team1Name].pts+= 3; teamsData[team2Name].pts+= 0; teamsData[team1Name].won += 1; teamsData[team2Name].lost += 1 }
              if(team1Goals < team2Goals) { teamsData[team2Name].pts+= 3; teamsData[team1Name].pts+= 0; teamsData[team2Name].won += 1; teamsData[team1Name].lost += 1 }
              if(team1Goals === team2Goals) { teamsData[team1Name].pts+= 1; teamsData[team2Name].pts +=1; teamsData[team2Name].draw += 1;  teamsData[team1Name].draw += 1 }  
            }
          })
        });

        tournament.data.map(team => {
          for(var teamName in teamsData) {
            if(teamName === team.name) {
              team.goalsF = teamsData[teamName].goalsF;
              team.goalsA = teamsData[teamName].goalsA;
              team.won = teamsData[teamName].won;
              team.lost = teamsData[teamName].lost;
              team.pts = teamsData[teamName].pts;
              team.draw = teamsData[teamName].draw;
            }
          }
        })
      }
    });
    this.setState({tournaments: newTournaments})
  }
  // helpers
  // array Shuffler
  arrayShuffler = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }
  // Read more about Round-Robin tournaments 
  // https://en.wikipedia.org/wiki/Round-robin_tournament
  roundRobinGenerator = (playersData) => {
    let teams = [];
    playersData.map(player => {
      teams.push(Object.assign({},{name: player.name, goals: 0}));
    })
    let evenTeams, numOfTeams, numOfRounds, upperHalf = [], bottomHalf = [];
    let fixedTeam, shiftedTeams, robinStack = [], newRound=[], firstHalfOfRounds=[], secondHalfOfRounds=[], allRounds=[];

    numOfTeams = teams.length;
    // number of rounds in a robin tournmanet will always be numOfTeams -1
    // however in this case we will duplicate the matches at the end
    // because we want a 2 legs tournamnet
    evenTeams = numOfTeams % 2 === 0;

    evenTeams === true
      ? numOfRounds = numOfTeams-1
      : numOfRounds = numOfTeams
    
    // In Round-Robin tournaments 1 team stays in position
    // While the rest of the teams rotate
    if(evenTeams) {fixedTeam = teams[0]; shiftedTeams = teams.slice(1)}
    if(!evenTeams) {fixedTeam = 'bye'; shiftedTeams = teams}    

    // console.log(fixedTeam)
    for(let i = 0; i < numOfRounds; i++) {
      for(let j = 0; j < shiftedTeams.length; j++) {
        // we are basically moving each element in the array
        // one position up
        if(j === 0) {
          // the first position in the robin stack
          // will be the last element in the original collection
          robinStack.push(shiftedTeams[shiftedTeams.length -1])
        } else {
          // every other position will be a position ahead 
          // compared the original collection
          robinStack.push(shiftedTeams[j-1])
        }
      }
      // once all elements in this iteration have moved 1 step up
      // we set upperHalf and bottomHalf values which splits robinStack in half
      upperHalf = [fixedTeam].concat(robinStack.slice(0, robinStack.length/2))
      bottomHalf = robinStack.slice(robinStack.length/2).reverse();
      // once upperHalf and Bottomhalf are set
      // we can match the teams for each round
      for(let i=0; i < upperHalf.length; i++) {
        if(upperHalf[i] === 'bye') {continue} // if the team is 'bye' we will skip this match, this is for odd teams tournaments
        newRound.push([upperHalf[i], bottomHalf[i], {finished: false}]);
      }
      // in the next iteration, we need to move up 1 step the elements
      // in the current position, we set shiftedTeams to equal our current stack
      shiftedTeams = robinStack;
      // at this point we have all the matches we need
      // for the current round
      firstHalfOfRounds.push(newRound);
      // since this tournament will have to legs,
      // we want to reverse the matches so that every team will play
      // agains each other both home and away
      let swappedMatches = newRound.map(match => match = [match[1], match[0], match[2]])
      secondHalfOfRounds.push(swappedMatches);
      // we reset the values of our robinStack
      // and our currentRound
      robinStack = [];
      newRound = [];
    }
    allRounds = firstHalfOfRounds.concat(secondHalfOfRounds);

    // In order to avoid teams to be modified by reference
    // We will loop through every match and assign a new object to every team
    // Otherwise everytime we make a change for a team it will also change the same team 
    // For a different match
    // let tempTeam1, tempTeam2;
    allRounds.map(round => {
      round.map(matches => {
        matches[0] = Object.assign({}, matches[0]);
        matches[1] = Object.assign({}, matches[1])
      })
    })
    return allRounds;
  }

  cupRoundsGenerator = (teams) => {

  }

  render() { 
    return ( 
        <TournamentsContext.Provider value={{
          ...this.state, 
          toggleDetails: this.toggleDetails, 
          toggleCreateTourModal: this.toggleCreateTourModal,
          addPlayer: this.addPlayer,
          generateTournament: this.generateTournament,
          changePlayerName: this.changePlayerName,
          updateMatchResults: this.updateMatchResults,
          toggleGameIsFinished: this.toggleGameIsFinished
        }}>
          {this.props.children}
        </TournamentsContext.Provider>
     );
  }
}
 
export default TournamentProvider;