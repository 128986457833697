import React, {Component, Fragment} from 'react';
import './createTourBtn.css'

// Components
import CreateTourModal from '../createTourModal/createTourModal';
import { TournamentsContext } from '../../contexts/TournamentProvider/TournamentProvider';


export default class CreateTourBtn extends Component {
  static contextType = TournamentsContext;

  render() {
    let context = this.context;
    let creatingTour = context.creatingTour;
    
    return(
      <Fragment>
        <button onClick={context.toggleCreateTourModal} className="create-tournament-btn">Start Tournament</button>
        {
          creatingTour 
            ? <CreateTourModal/>
            : null
        }
      </Fragment>
    )
  }
}