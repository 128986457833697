import React, {Component, Fragment} from 'react';
import './tournaments.css'
// import testData from '../../testData';

// Components
import TournamentDetails from '../tournamentDetails/tournamentDetails';
// import ViewEditBtns from '../viewEditBtns/viewEditBtns';
import { TournamentsContext } from '../../contexts/TournamentProvider/TournamentProvider';

export default class Tournaments extends Component {
  static contextType = TournamentsContext;
  
  render() {
    let tournaments = this.context.tournaments;
    let reversedTournaments =[];
    let cupTrophy = require('../../images/cup-trophy.svg');
    let leagueTrophy = require('../../images/league-trophy.svg');

    if (tournaments.length > 0 ){
      tournaments.map(tournament => reversedTournaments.unshift(tournament))
      return(
        reversedTournaments.map((tournament, key) =>{
          return (
            <div className="tournament-container" onClick={()=>{this.context.toggleDetails(tournament._id)}} key={tournament['_id']}>
              <div className='tournament-trophy-icon-container'>
                {
                  tournament.type === "cup"
                    ? <img className="trophy-icon" src={cupTrophy} alt='cup trophy icon'/> 
                    : <img className="trophy-icon" src={leagueTrophy} alt='league trophy icon'/> 
                }
              </div>
              <div className='tournamnet-details-container'>
                <div className="tournament-title">{tournament.title}</div>
                <div><b>Champion:</b> {tournament.winner}</div>
              </div>
              <TournamentDetails toggleDetails={()=>{this.context.toggleDetails(tournament['_id'])}} tournament = {tournament}/>
            </div>
          )
        })
      )
    } else {
      return (
        <Fragment>
          <div className="no-tournaments-msg">
            You don't have any tournaments.
          </div>
        </Fragment>
      )
    }
    
  }
}