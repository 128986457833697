import React, {Component} from 'react';
import './createTourModal.css';

// Components
import CreateTourOptions from '../createTourOptions/createTourOptions';
import { TournamentsContext } from '../../contexts/TournamentProvider/TournamentProvider';

export default class CreateTourModal extends Component {  
  static contextType = TournamentsContext;

  render() {
    let toggleCreateTourModal = this.context.toggleCreateTourModal;

    return (
      <div onClick={toggleCreateTourModal} className="create-tournament-modal-dark-background">
        <div onClick={e=>{e.stopPropagation()}} className='create-tournament-modal'>
          {/* Top Right close button */}
          <span onClick={toggleCreateTourModal} style={{position: 'absolute', top: '1em', right: "1em"}}>x</span>

          <CreateTourOptions />
        </div>
      </div>
    )
  }
}