import React, { Component } from 'react';
import { TournamentsContext } from '../../contexts/TournamentProvider/TournamentProvider';

class RenderLeagueGames extends Component {  
  static contextType = TournamentsContext;

  render() {
    let tournament = this.props.tournament;
    let context = this.context;

    return ( 
      <React.Fragment>
        {
          tournament.rounds.map((round, roundIndex) => {
            // console.log('round renderLeague', round)
            return (
              <div className="round-container-wrapper" key={roundIndex}>
                <h3 className="round-title">{`Round ${roundIndex + 1}`}</h3>
                <div className="round-details-container">
                  { 
                    round.map((match, matchIndex) => {
                      let player1Index = 0, player2Index= 1;
                      return (
                        <div className="match-container" key={matchIndex}>
                          <div className="match-team1">
                            {match[player1Index].name}
                            <input className="match-score-team1" placeholder='0' value={match[0].goals} onChange={(e)=>{context.updateMatchResults(e.target.value, tournament["_id"], roundIndex, matchIndex, player1Index, match[0].name)}} type="number"/>
                          </div>
                          <span className="match-vs">vs</span> 
                          <div className="match-team2">
                            <input className="match-score-team2" placeholder='0' value={match[1].goals} onChange={(e)=>{context.updateMatchResults(e.target.value, tournament["_id"], roundIndex, matchIndex, player2Index, match[1].name)}} type="number"/>
                            {match[player2Index].name}
                          </div> 
                          <div className="is-finished-checkbox">
                            {
                              match[2]['finished'] === true 
                              ? 
                                <label className="checkbox-label">
                                  <input onChange={()=>{context.toggleGameIsFinished(tournament["_id"], roundIndex, matchIndex)}} checked type="checkbox"/>
                                  <span class="checkbox-custom rectangular"></span>
                                </label>
                              : 
                                <label className="checkbox-label"> 
                                  <input onChange={()=>{context.toggleGameIsFinished(tournament["_id"], roundIndex, matchIndex)}} type="checkbox"/>
                                  <span className="checkbox-custom rectangular"></span>
                                </label>
                            }
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            )
          })
        }
      </React.Fragment>
     );
  }
}
 
export default RenderLeagueGames;