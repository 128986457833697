import React, {Component} from 'react';
import TournamentTable from '../tournamentTable/tournamentTable';
import './tournamentDetails.css';
// Components
import RenderCupBrackets from './renderCupBrackets';
import RenderLeagueGames from './renderLeagueGames';


export default class TournamentDetails extends Component {
  
  render(){
    let tournament = this.props.tournament;
    let isActive = tournament.isActive;

    return (
      <div onClick={e=>{e.stopPropagation()}} className={isActive ? "tournament-details-wrapper-active smooth-transition" : "tournament-details-wrapper smooth-transition"}>
        <div onClick={this.props.toggleDetails} className="close-tournament-details-btn">X</div>
        <div className="details-tournament-title">{tournament.title}</div>
        {/* Cup / League details */}
        {
          tournament.type === 'cup'
            ? <RenderCupBrackets tournament={tournament} />
            : <RenderLeagueGames tournament={tournament} />
        }
        {/* Details Content */}
        <TournamentTable tournament={tournament}/>
      </div>
    )
  }
}