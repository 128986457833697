import React, {Component} from 'react';
import './App.css';

// components
import CreateTourBtn from './components/createTourBtn/createTourBtn';
import Tournaments from './components/tournaments/tournaments';
import TournamentProvider from './contexts/TournamentProvider/TournamentProvider';


class App extends Component {
  render() {
    return (
      <div className="App">
        <TournamentProvider>
          <header className='app-header'>
            <span className="app-title">FIFA Tour </span>
            <CreateTourBtn />
          </header>
          <Tournaments />
        </TournamentProvider>
      </div>
    );
  }
}

export default App;
