import React, { Component } from 'react';
import './cupBrackets.css'
import { TournamentsContext } from '../../contexts/TournamentProvider/TournamentProvider';

class RenderCupBrackets extends Component {
  constructor(props) {
    super(props)
    this.onBracketScroll = this.onBracketScroll.bind(this);
  }
  onBracketScroll(e) {
    // console.log(e)
    // console.log(e.target)
    // console.log(e.target.scrollLeft)

    let scrollPosition = e.target.scrollLeft
    if(scrollPosition >= 160) {
      let round16elements = Array.from(document.querySelectorAll('.round-16 .round-match'))
      let round8elements = Array.from(document.querySelectorAll('.round-8 .round-match'))
      let round4elements = Array.from(document.querySelectorAll('.round-4 .round-match'))
      let roundFinalelements = Array.from(document.querySelectorAll('.round-final .round-match'))
      
      round16elements.map(matchContiner => {matchContiner.style.height = '45px'})
      round8elements.map(matchContiner => {matchContiner.style.margin=  "41px 0"; matchContiner.style.marginTop = '27px'})
      round4elements.map(matchContiner => {matchContiner.style.margin=  "178px 0"; matchContiner.style.marginTop = '92px'})
      roundFinalelements.map(matchContiner => {matchContiner.style.margin=  "245px 0";})
    }
    if(scrollPosition < 160) {
      let round16elements = Array.from(document.querySelectorAll('.round-16 .round-match'))
      let round8elements = Array.from(document.querySelectorAll('.round-8 .round-match'))
      let round4elements = Array.from(document.querySelectorAll('.round-4 .round-match'))
      let roundFinalelements = Array.from(document.querySelectorAll('.round-final .round-match'))

      round16elements.map(matchContiner => {matchContiner.style.height = '90px'})
      round8elements.map(matchContiner => {matchContiner.style.margin=  "130px 0"; matchContiner.style.marginTop = '75px'})
      round4elements.map(matchContiner => {matchContiner.style.margin=  "360px 0"; matchContiner.style.marginTop = '185px'})
      roundFinalelements.map(matchContiner => {matchContiner.style.margin=  "400px 0";})
    }
    // if(scrollPosition >= 330) {
    //   let round4elements = Array.from(document.querySelectorAll('.round-4 .round-match'))
    //   let roundFinalelements = Array.from(document.querySelectorAll('.round-final .round-match'))

    //   round4elements.map(matchContiner => {matchContiner.style.margin=  "27px 0px 46px";})
    //   roundFinalelements.map(matchContiner => {matchContiner.style.margin=  "90px 0px";})
    // }
  }

  static contextType = TournamentsContext;

  render() { 
    let context = this.context;
    let tournament = this.props.tournament;
    let rounds = tournament.rounds;

    return ( 
      <React.Fragment>
        <div className="brackets-container" onScroll={(e)=>{this.onBracketScroll(e)}}>
        {/* <!-- Round of 16 --> */}
        <div className="round-16 round-container" style={{display: "inline-block", verticalAlign: "top"}}>
          <div className="round-outter-wrapper">
           <div className="round-title-cup">Round of 16</div>
            {
              Array.from(Array(8), (e, i) => {
                if(i%2 === 1) return null
                return (
                  <div className="round-matches" key={"round16-"+i}>
                    <div className="top-horizontal-dash"></div>
                    <div className="round-match">
                      <div className="round-match-team">{rounds.roundOf16[i] == undefined ? <span className="na-player">n/a</span> : rounds.roundOf16[i][0] == null ? <span className="bye-player">Bye</span> : rounds.roundOf16[i][0].name}
                      </div>
                      <div className="round-match-team">{rounds.roundOf16[i] == undefined ? <span className="na-player">n/a</span> : rounds.roundOf16[i][1] == null ? <span className="bye-player">Bye</span> : rounds.roundOf16[i][1].name}
                      </div>
                    </div>
                    <div className="vertical-dash"></div>
                    <div className="bottom-horizontal-dash"></div>
                    <div className="center-horizontal-dash"></div>
                    <div className="round-match">
                      <div className="round-match-team">{rounds.roundOf16[i+1] == undefined ? <span className="na-player">n/a</span> : rounds.roundOf16[i+1][0] == null ? <span className="bye-player">Bye</span> : rounds.roundOf16[i+1][0].name}</div>
                      <div className="round-match-team">{rounds.roundOf16[i+1] == undefined ? <span className="na-player">n/a</span> : rounds.roundOf16[i+1][1] == null ? <span className="bye-player">Bye</span> : rounds.roundOf16[i+1][1].name}</div>
                    </div>
                  </div>
                )
              })
            }            
          </div>
        </div>
        {/* <!-- Quarter Finals --> */}
        <div className="round-8 round-container" style={{display: "inline-block", verticalAlign: "top"}}>
          <div className="round-outter-wrapper">
            <div className="round-title-cup">Quarter Finals</div>
            {
              Array.from(Array(4), (e, i) => {
                if(i%2 === 1) return null
                return (
                  <div className="round-matches" key={"QF-"+i}>
                    <div className="top-horizontal-dash"></div>
                    <div className="round-match">
                      <div className="round-match-team">{rounds.quarterFinals[i] == undefined ? <span className="na-player">n/a</span> : rounds.quarterFinals[i][0] == null ? <span className="tbd-player">TBD</span> : rounds.quarterFinals[i][0] == 'bye' ? <span className="bye-player">Bye</span> : rounds.quarterFinals[i][0].name}</div>
                      <div className="round-match-team">{rounds.quarterFinals[i] == undefined ? <span className="na-player">n/a</span> : rounds.quarterFinals[i][1] == null ? <span className="tbd-player">TBD</span> : rounds.quarterFinals[i][1] == 'bye' ? <span className="bye-player">Bye</span> : rounds.quarterFinals[i][1].name}</div>
                    </div>
                    <div className="vertical-dash"></div>
                    <div className="bottom-horizontal-dash"></div>
                    <div className="center-horizontal-dash"></div>
                    <div className="round-match">
                      <div className="round-match-team">{rounds.quarterFinals[i+1] == undefined ? <span className="na-player">n/a</span> : rounds.quarterFinals[i+1][0] == null ? <span className="tbd-player">TBD</span> : rounds.quarterFinals[i+1][0] == 'bye' ? <span className="bye-player">Bye</span> : rounds.quarterFinals[i+1][0].name}</div>
                      <div className="round-match-team">{rounds.quarterFinals[i+1] == undefined ? <span className="na-player">n/a</span> : rounds.quarterFinals[i+1][1] == null ? <span className="tbd-player">TBD</span> : rounds.quarterFinals[i+1][1] == 'bye' ? <span className="bye-player">Bye</span> : rounds.quarterFinals[i+1][1].name}</div>
                    </div>
                  </div>
                )
              })
            }
          </div> 
        </div>
        {/* <!-- Semi Final --> */}
        <div className="round-4 round-container" style={{display: "inline-block", verticalAlign: "top"}}>
          <div className="round-outter-wrapper">
            <div className="round-title-cup">Semi Finals</div>
            <div className="round-matches">
              <div className="top-horizontal-dash"></div>
                <div className="round-match">
                  <div className="round-match-team">{rounds.semiFinals[0] == undefined ? <span className="na-player">n/a</span> : rounds.semiFinals[0][0] == null ? <span className="tbd-player">TBD</span> : rounds.semiFinals[0][0].name}</div>
                  <div className="round-match-team">{rounds.semiFinals[0] == undefined ? <span className="na-player">n/a</span> : rounds.semiFinals[0][1] == null ? <span className="tbd-player">TBD</span> : rounds.semiFinals[0][1].name}</div>
                </div>
                <div className="vertical-dash"></div>
                <div className="bottom-horizontal-dash"></div>
                <div className="center-horizontal-dash"></div>
                <div className="round-match">
                  <div className="round-match-team">{rounds.semiFinals[1] == undefined ? <span className="na-player">n/a</span> : rounds.semiFinals[1][0] == null ? <span className="tbd-player">TBD</span> : rounds.semiFinals[1][0].name}</div>
                  <div className="round-match-team">{rounds.semiFinals[1] == undefined ? <span className="na-player">n/a</span> : rounds.semiFinals[1][1] == null ? <span className="tbd-player">TBD</span> : rounds.semiFinals[1][1].name}</div>
                </div>
            </div>
          </div>
        </div>
      {/* <!-- Final --> */}
      <div className="round-final round-container" style={{display: "inline-block", verticalAlign: "top"}}>
        <div className="round-outter-wrapper">
          <div className="round-matches">
            <div className="top-horizontal-dash"></div>
            <div className="vertical-dash"></div>
            <div className="bottom-horizontal-dash"></div>
            <div className="center-horizontal-dash"></div>
            <div className="round-match round-match-final">
              <div className="round-title round-title-final">Final</div>
              <div className="round-match-team">{rounds.final[0] == undefined ? <span className="tbd-player">TBD</span> : rounds.final[0][0].nam == null ? '' : rounds.final[0][0].name}</div>
              <div className="round-match-team">{rounds.final[0] == undefined ? <span className="tbd-player">TBD</span> : rounds.final[0][1].nam == null ? '' : rounds.final[0][1].name}</div>
            </div>
          </div>
        </div>
      </div>
      <div style={{width: "500px", height:"200px", display: "inline-block"}}></div>
    </div>
      </React.Fragment>
     );
  }
}
 
export default RenderCupBrackets;