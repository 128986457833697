import React, {Component} from 'react';
import './tournamentTable.css';
import { TournamentsContext } from '../../contexts/TournamentProvider/TournamentProvider';

export default class TournamentTable extends Component {
  
  state = {
    dragTable: false
  }

  dragTable = () => {
    this.setState({dragTable: !this.state.dragTable})
  }

  static contextType = TournamentsContext;

  
  render(){
  let data = this.props.tournament.data;
  
  data.sort((a,b) => (a.pts > b.pts) ? -1 : 1 )
  // We will use the variable tableResultsBottomValue 
  // to determine the position bottom value of the table results
  // That way regardless of the number of users, 
  // it will collapse correctly for every tournamnet
  let tableResultsBottomValue = -9 - (data.length - 4) * 2.14;
  tableResultsBottomValue = tableResultsBottomValue + "em"

  let count = 1;
    return(
      <div className={this.state.dragTable ? 'tournament-table-results dragged-table' : 'tournament-table-results'} style={this.state.dragTable ? {bottom: "0"} : {bottom: tableResultsBottomValue}}>
        <div className="tournament-table-draggable" onClick={this.dragTable}> <span className="long-dash"> </span> </div>
        <div className='tournament-table-results-names'>
          <table className="tournament-tables">
            <thead className="table-results-name-header"> 
              <tr>
                <th className="table-results-table-header">Name</th>
              </tr>
            </thead>
            
            <tbody>
              {data.map((data, key) => {
                return (
                  <tr key={key} >
                    <th className="table-results-names">{count++}  {data.name}.</th>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className='tournament-table-results-numbers'>
          <table className="tournament-tables">
            <thead className="table-results-numbers-header">
              <tr>
                <th className="table-results-table-header">W</th>
                <th className="table-results-table-header">D</th> 
                <th className="table-results-table-header">L</th>
                <th className="table-results-table-header" style={{color:"black"}}><b>Pts</b></th>
                <th className="table-results-table-header">GF</th> 
                <th className="table-results-table-header">GA</th>
              </tr>
            </thead>
            <tbody>
              {data.map((data, key) => {
                return (
                  <tr key={key}>
                    <th className="table-results-numbers">{data.won}</th>
                    <th className="table-results-numbers">{data.draw}</th> 
                    <th className="table-results-numbers">{data.lost}</th>
                    <th className="table-results-numbers"><b>{data.pts}</b></th>
                    <th className="table-results-numbers">{data.goalsF}</th> 
                    <th className="table-results-numbers">{data.goalsA}</th>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}