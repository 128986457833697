import React, { Component, Fragment } from 'react';
import { TournamentsContext } from '../../contexts/TournamentProvider/TournamentProvider';

class Players extends Component {
  static contextType = TournamentsContext; 

  render() {
    let context = this.context;
    let players = context.createTourFields.players;


  
    return ( 
      <Fragment>
        <div className='add-players-title'>PLAYERS</div>
        {
          players.map((player, key)=>{
            return (
              <div key={player.key} className="player-name-input-wrapper">
                <label className="player-name-input-label">Player{player.key + 1}</label>
                <input className="player-name-input" data-playerid={player.key} key={player.key} value={player.name} onChange={context.changePlayerName} type="text"/>                
              </div>
            )
          })
        }
        
        <button className="add-player-btn" onClick={context.addPlayer}>Add Player</button>

      </Fragment>
     );
  }
}
 
export default Players;